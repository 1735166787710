@use "./assets/scss/mixin" as *;
@use "./assets/scss/colors" as *;

.app {
  background-image: linear-gradient($app_bg_c_darker, $app_bg_c_lighter);
  height: 100vh;
  display: flex;
  justify-content: center;
  // align-items: stretch;

  .todo-card {
    margin: 30px 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
    padding: 10px 20px;

    .todo-form {
      margin-bottom: 20px;
      display: flex;
      width: 100%;
    }
    .todos-container {
      overflow-y: auto;
      margin-bottom: 20px;
      width: 100%;
      scroll-behavior: smooth;
      ol {
        // width: 100%;
        padding-inline-start: 15px;
        margin: 0;
        li {
          font-size: 16px;
          font-weight: 500;
          border-bottom: 1px solid $border_c;
        }
        > :last-child {
          border-bottom: none;
        }
      }
    }
  }
}
.input-area {
  display: flex;
  flex-grow: 1;
  width: 100%;
  .input-field {
    width: 100%;

    // font-size: 16px;
    // flex-grow: 1;
    // max-width: 110px;
    border-radius: 50px;
    padding-inline-start: 15px;
    // margin-inline-end: 5px;
    outline: none;
    border: 2px solid $border_c;
    &:focus {
      border-color: $blue;
    }
  }
}

.btn {
  flex-shrink: 0;
  flex-grow: 1;
  border: none;
  border-radius: 5px;
  margin-inline-start: 4px;
  padding: 8px 12px;
  cursor: pointer;
  img {
    filter: brightness(0) invert(1);
  }
  transition: outline 0.4s;
  outline: 4px solid transparent;
}

@each $name, $color, $bg_color in $btn_arr {
  .btn-#{$name} {
    @include btn_contained($name, $color, $bg_color);
  }
}

@media only screen and (max-width: 576px) {
  .todo-card {
    min-width: 250px;
    max-width: 350px;
  }
  .todo-form {
    flex-wrap: wrap;
    .btn {
      margin-top: 10px;
    }
    > :nth-child(2).btn {
      margin-inline-start: 0;
      // margin-inline-end: 10px;
    }
  }
  .input-field {
    height: 36px;
  }
}
