.todo {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-block: 13px;
  font-size: initial;
  font-weight: initial;
  :nth-child(2) {
    // width: 100%;
    flex-grow: 1;
    // max-width: 90px;
  }
  label {
    margin-block: auto;
    margin-inline-end: 10px;
    white-space: normal;
    word-break: break-word;
    h2 {
      font-size: 18px;
      margin: 0;
    }
  }
}
.checkbox-input {
  width: 24px;
  height: 24px;
  margin-inline-end: 10px;
  flex-shrink: 0;
}
.btn-container {
  display: flex;
  // flex-grow: 1;
  flex-wrap: nowrap;
}
@media only screen and (max-width: 576px) {
  .todo {
    flex-wrap: wrap;
  }
  .btn-container {
    margin-top: 10px;
    width: 100%;
    :first-child.btn {
      margin-inline-start: 0px;
    }
  }
}
