@use "../../assets/scss/colors" as *;
// .overlay {
//   position: fixed;
//   z-index: 1;
//   top: 0;
//   bottom: 0;
//   left: 0;
//   right: 0;
//   cursor: pointer;
//   background-color: rgba($color: #000000, $alpha: 0.5);
// }
.details-card {
  display: flex;
  flex-direction: column;
  margin-bottom: 0px;
  border-radius: 15px;
  padding: 0 4px;

  max-height: 0;
  transition: 0.3s;
  overflow: hidden;
  // border: 2px solid transparent;

  &.active {
    max-height: 2000px;
    margin-bottom: 25px;
    transition: 0.3s;
    // border-color: $red;
  }

  .details-div {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    h3 {
      margin: 0;
    }
    button {
      flex-grow: 0;
      justify-self: flex-end;
    }
  }
  hr {
    width: 100%;
    border-top: 1px solid $red;
    border-bottom: none;
  }
  .details-paragraph {
    white-space: pre;
    word-break: break-word;

    // max-height: 0;
    // transition: 0.5s;
    // overflow: hidden;
    // // border: 2px solid transparent;

    // &.active {
    //   max-height: 2000px;
    //   transition: 0.5s;
    //   // padding: 15px;
    //   //   border-color: $border_c;
    // }
  }
  .input-textarea {
    // overflow: scroll;
    border-radius: 15px;
    height: 200px;
    resize: none;
    outline: none;
    padding: 0;
    font-size: 16px;
    color: $border_c;

    max-height: 0;
    transition: 0.5s;
    // overflow: hidden;
    border: 0px solid transparent;

    &.active {
      max-height: 2000px;
      padding: 15px;
      border-color: $border_c;
      border-width: 2px;
    }

    &:focus {
      border-color: $blue;
    }
  }
  //   position: fixed;
  //   z-index: 2;
  //   align-items: stretch;
  //   min-width: 250px;
  //   min-height: 60vh;
  //   top: 10vh;
  //   margin: auto;
  //   //   padding: 10px 20px;
  //   color: #000000;
  //   background-color: white;
  //   border-radius: 15px;
  //   box-shadow: 0 0 10px 0 rgba($color: #000000, $alpha: 0.5);
  //   .details-name {
  //     display: flex;
  //     justify-content: space-between;
  //     align-items: center;
  //     padding-inline: 20px;
  //     border-radius: 15px;
  //     color: white;
  //     background-image: linear-gradient($app_bg_c_darker, $app_bg_c_lighter);
  //     > span {
  //       cursor: pointer;
  //       width: 26px;
  //       height: 26px;
  //       position: relative;
  //       span {
  //         position: absolute;
  //         top: 13px;
  //         width: 100%;
  //         height: 3px;
  //         background-color: #fff;
  //       }
  //       > span:first-child {
  //         transform: rotate(45deg);
  //       }
  //       > span:last-child {
  //         transform: rotate(135deg);
  //       }
  //     }
  //   }
  //   hr {
  //     border-color: $border_c;
  //     width: 100%;
  //   }
}
