@mixin btn_contained($name, $color, $bg_color) {
  color: $color;
  background-color: $bg_color;
  &:active {
    background-color: darken($color: $bg_color, $amount: 10);
  }
  &:focus {
    @if ($name == "normal") {
      outline-color: darken($color: rgba($bg_color, 0.6), $amount: 30);
    } @else {
      outline-color: lighten($color: rgba($bg_color, 0.3), $amount: 30);
    }
  }
}
