$green: rgb(0, 135, 0);
$blue: rgb(30, 30, 230);
$red: rgb(230, 30, 30);
$off_white: rgb(225, 225, 225);
$gold: rgb(255, 215, 0);
$app_bg_c_lighter: #b731e0;
$app_bg_c_darker: #862222;
$border_c: #555;

$btn_arr: (
  "add" white $green,
  "edit" white $blue,
  "delete" white $red,
  "normal" black $off_white,
  "warning" black $gold
);
